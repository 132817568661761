<template>
	<div v-show="pageShow">
		<my-dialog :title="title" :pageShow="pageShow" showCloseBtn @close="close_dialog">
			<div class="padding16">
				<el-form :inline="true" :model="param" ref="param" class="demo-form-inline">
					<el-form-item label="注册时间">
						<div class="time_box x_start_v">
							<el-date-picker size="mini" v-model="beginTime" type="datetime" placeholder="开始时间" @change="getPickerTime('beginTime')"></el-date-picker>
							<div class="line_box">-</div>
							<el-date-picker size="mini" v-model="endTime" type="datetime" placeholder="结束时间" @change="getPickerTime('endTime')"></el-date-picker>
						</div>
					</el-form-item>
					<el-form-item>
						<el-button size="mini" type="primary" :loading="loadingBtn" @click="onSubmit">查询</el-button>
					</el-form-item>
				</el-form>

				<el-table :data="list" v-loading="loadingShow" size="mini" border style="width: 100%">
					<el-table-column prop="t_idcard" label="用户ID"></el-table-column>
					<el-table-column prop="t_nickName" label="用户昵称"></el-table-column>
					<el-table-column prop="t_handImg" label="用户头像">
						<template slot-scope="scope">
							<div class="list_img_box">
								<img :src="scope.row.t_handImg" alt="">
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="city" label="性别">
						<template slot-scope="scope">
							<div>
								<div v-if="scope.row.t_sex == 0">女</div>
								<div v-if="scope.row.t_sex == 1">男</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="t_create_time" label="注册时间" width="220"></el-table-column>
					<el-table-column prop="zip" label="是否充值">
						<template slot-scope="scope">
							<div>
								<div v-if="scope.row.recharge_status == 0">未充值</div>
								<div v-if="scope.row.recharge_status == 1">已充值</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column fixed="right" label="操作" width="170">
						<template slot-scope="scope">
							<el-button icon="el-icon-document" type="text" size="small" @click="showDialog('rechargeDetailsShow',scope.row)">充值明细</el-button>
						</template>
					</el-table-column>
				</el-table>

				<el-pagination background layout="total, prev, pager, next, jumper" :page-size="param.pageSize" :current-page.sync="param.page"
				:total="total" @current-change="currentChange"></el-pagination>

			</div>
		</my-dialog>


		<rechargeDetails :title="'充值明细'" :pageShow="dialog.rechargeDetailsShow" :userId="searchserId" @close="showDialog('rechargeDetailsShow')"></rechargeDetails>
	</div>

</template>

<script>
	import rechargeDetails from "./rechargeDetails.vue"
	export default {
		components: {
			rechargeDetails
		},
		props: {
			userId: {
				type: Number
			},
			pageShow: {
				type: Boolean
			},
			title: {
				type: String
			}
		},
		data() {
			return {
				param: {
					t_user_id: null, //  用户Id
					t_role: 0, //  	0 用户  1主播
					searchParam: null, //   查询Id	
					beginTime: null, //  	开始时间
					endTime: null, //  	结束时间
					page: 1, //   1
					pageSize: 10, //   10
				},
				beginTime: null, //  	开始时间
				endTime: null, //  	结束时间
				loadingShow: false,
				loadingBtn:false,
				list: [],
				total: 0,
				
				dialog: {
					rechargeDetailsShow: false,
				},
				searchserId:null	
			}
		},
		watch: {
			//正确给 cData 赋值的 方法
			userId: function(newVal, oldVal) {
				if(this.pageShow == false){
					return
				}
				console.log("oldVal",oldVal)
				this.param.t_user_id = newVal; //newVal即是chartData
				
				if(newVal){
					this.clearParam()
				}
			
			}
		},
	
		methods: {
			clearParam(){
				this.param.beginTime = null
				this.param.endTime = null
				this.param.page = 1
				this.beginTime = null
				this.endTime = null
				this.total = 0
				this.getList(); //newVal存在的话执行drawChar函数
			},
			getPickerTime(name) {
				console.log("name=", name, this.beginTime)

				if (this[name]) {
					let timeParam = this.getTime(this[name])
					let time = timeParam.year + '-' + timeParam.month + '-' + timeParam.day + ' ' + timeParam.h + ':' + timeParam.m +
						':' + timeParam.s
					this.param[name] = time
					console.log("this.param", this.param)
				} else {
					this.param[name] = null
				}


			},
			async onSubmit() {
				console.log('submit!');
				this.loadingBtn = true
				await this.getList()
				this.loadingBtn = false
			},
			async getList() {
				console.log("save")
				let that = this
				let param = {
					url: "myInviteUserList",
					data: this.param
				}
				this.loadingShow = true
				await this.api(param).then(res => {
					that.list = []
					if (res.status == 0) {
						that.list = res.data.list
						that.total = res.data.total
					}
				})
				this.loadingShow = false
			},
			currentChange(page) {
				this.param.page = page
				this.getList()
			},
			close_dialog() {
				console.log("222222")
				this.$emit("close")
			},
			showDialog(dialogName, item = null) {
				console.log("关闭", dialogName)
				if (item !== null) {
					console.log("item", item)
					this.searchserId = item.t_user_id
				}else{
					this.searchserId = null
				}
				this.dialog[dialogName] = !this.dialog[dialogName]
			}
		}
	}
</script>

<style scoped="">
	.el-form-item {
		margin-bottom: 0 !important;
	}

	.el-table {
		margin-top: 10px;
	}
</style>
